import React from "react"
import { Link, graphql } from "gatsby"
import Helmet from "react-helmet";
import Layout from "../Layout/Layout"
import PostListing from "../components/PostListing/PostListing"
import config from "../../data/SiteConfig";

class IndexPage extends React.Component {
  render() {
    const blogPosts = this.props.data.posts.edges

    return (
      <>
        <Helmet title={config.siteTitle} />
        <Layout withSideNav>
          <div className="listing-container">
            <div className="posts-container">
              <PostListing postEdges={blogPosts} />
            </div>
            <Link to="/articles">More Articles...</Link>
          </div>
        </Layout>
      </>
    )
  }
}

export default IndexPage


export const pageQuery = graphql`
  query {
    posts: allMarkdownRemark(
      limit: 5
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
            categorySlug
          }
          excerpt(pruneLength: 600)
          timeToRead
          frontmatter {
            title
            date
            category
            description
          }
        }
      }
    }
  }
`